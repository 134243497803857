import {DashboardLayout} from "../../layout/dashboard.layout";
import {useTranslation} from "react-i18next";
import {DASHBOARD, NUMBERS, SENDER_NAMES} from "../../../routes/frontend.routes";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {SenderNameForm} from "./form";
import {jsonRequest} from "../../../../api/request/request";
import {SENDER_NAME_EDIT} from "../../../../api/routing/routes/admin.backend.app";
import {HttpException, UnprocessableEntityException} from "../../../../lib/http/exception/http.exception";
import {useAlert} from "react-alert";
import {useNavigate} from "react-router";
import {SenderName} from "../../../../api/model/user";

export const UpdateSenderName = () => {
  const {t} = useTranslation();
  const alert = useAlert();
  const params = useParams();
  const id = params.id as string;

  const [isSubmitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const [senderName, setSenderName] = useState<SenderName>();

  const loadSenderName = async () => {
    try {
      const res = await jsonRequest(SENDER_NAME_EDIT.replace(':id', id));
      const json = await res.json();

      setSenderName(json.senderName);
    } catch (e) {
      if (e instanceof HttpException) {
        alert.error(e.message);
      }

      throw e;
    }
  }

  useEffect(() => {
    loadSenderName();
  }, []);

  const onSubmit = async (values: any) => {
    setSubmitting(true);
    setErrors([]);

    try {
      await jsonRequest(SENDER_NAME_EDIT.replace(':id', id), {
        method: 'POST',
        body: JSON.stringify(values)
      });

      navigate(SENDER_NAMES);

      alert.success(t('Updated!'));
    } catch (e) {
      if (e instanceof UnprocessableEntityException) {
        const err = await e.response.json();
        setErrors(err.violations);
      }

      if (e instanceof HttpException) {
        alert.error(e.message);
      }

      throw e;
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <DashboardLayout
      title="Sender Names"
      breadCrumbs={[
        {title: t('Dashboard'), link: DASHBOARD},
        {title: t('Sender Names'), link: NUMBERS},
        {title: t('Update'), current: true},
      ]}
      buttons={[
        <Link to={SENDER_NAMES} className="float-end btn btn-primary">{t('Back to list')}</Link>
      ]}
    >
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                {t('Update')}
              </h5>
              <SenderNameForm
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                errors={errors}
                senderName={senderName}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
