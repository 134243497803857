import {DashboardLayout} from "../../layout/dashboard.layout";
import {useTranslation} from "react-i18next";
import {DASHBOARD, TEMPLATES} from "../../../routes/frontend.routes";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {TemplateForm} from "./form";
import {jsonRequest} from "../../../../api/request/request";
import {TEMPLATE_CREATE} from "../../../../api/routing/routes/admin.backend.app";
import {HttpException, UnprocessableEntityException} from "../../../../lib/http/exception/http.exception";
import {useAlert} from "react-alert";
import {useNavigate} from "react-router";

export const CreateTemplate = () => {
  const {t} = useTranslation();
  const alert = useAlert();

  const [isSubmitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const onSubmit = async (values: any) => {
    setSubmitting(true);
    setErrors([]);

    try{
      await jsonRequest(TEMPLATE_CREATE, {
        method: 'POST',
        body: JSON.stringify(values)
      });

      navigate(TEMPLATES);

    }catch (e){
      if(e instanceof UnprocessableEntityException){
        const err = await e.response.json();
        setErrors(err.violations);
      }

      if(e instanceof HttpException){
        alert.error(e.message);
      }

      throw e;
    }finally {
      setSubmitting(false);
    }
  }

  return (
    <DashboardLayout
      title="Templates"
      breadCrumbs={[
        {title: t('Dashboard'), link: DASHBOARD},
        {title: t('Templates'), link: TEMPLATES},
        {title: t('Create'), current: true},
      ]}
      buttons={[
        <Link to={TEMPLATES} className="float-end btn btn-primary">{t('Back to list')}</Link>
      ]}
    >
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                {t('Create')}
              </h5>
              <TemplateForm
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                errors={errors}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
