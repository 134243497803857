import {DashboardLayout} from "../../layout/dashboard.layout";
import {useTranslation} from "react-i18next";
import {DASHBOARD, PACKAGES, TEMPLATES} from "../../../routes/frontend.routes";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {PackageForm} from "./form";
import {jsonRequest} from "../../../../api/request/request";
import {PACKAGE_EDIT, PACKAGE_GET, TEMPLATE_EDIT} from "../../../../api/routing/routes/admin.backend.app";
import {HttpException, UnprocessableEntityException} from "../../../../lib/http/exception/http.exception";
import {useAlert} from "react-alert";
import {useNavigate} from "react-router";
import {Package} from "../../../../api/model/package";

export const UpdatePackage = () => {
  const {t} = useTranslation();
  const alert = useAlert();
  const params = useParams();
  const id = params.id as string;

  const [isSubmitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const [pkg, setPackage] = useState<Package>();

  const loadPackage = async () => {
    try {
      const res = await jsonRequest(PACKAGE_GET.replace(':id', id));
      const json = await res.json();

      setPackage(json.package);
    } catch (e) {
      if (e instanceof HttpException) {
        alert.error(e.message);
      }

      throw e;
    }
  }

  useEffect(() => {
    loadPackage();
  }, []);

  const onSubmit = async (values: any) => {
    setSubmitting(true);
    setErrors([]);

    try {
      await jsonRequest(PACKAGE_EDIT.replace(':id', id), {
        method: 'POST',
        body: JSON.stringify(values)
      });

      navigate(TEMPLATES);
    } catch (e) {
      if (e instanceof UnprocessableEntityException) {
        const err = await e.response.json();
        setErrors(err.violations);
      }

      if (e instanceof HttpException) {
        alert.error(e.message);
      }

      throw e;
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <DashboardLayout
      title="Packages"
      breadCrumbs={[
        {title: t('Dashboard'), link: DASHBOARD},
        {title: t('Packages'), link: PACKAGES},
        {title: t('Update'), current: true},
      ]}
      buttons={[
        <Link to={PACKAGES} className="float-end btn btn-primary">{t('Back to list')}</Link>
      ]}
    >
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                {t('Update')}
              </h5>
              <PackageForm
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                errors={errors}
                package={pkg}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
