import {DashboardLayout} from "../../layout/dashboard.layout";
import {useTranslation} from "react-i18next";
import {DASHBOARD, NUMBERS_CREATE, NUMBERS_EDIT} from "../../../routes/frontend.routes";
import {createColumnHelper} from "@tanstack/react-table";
import {IndeterminateCheckbox} from "../../../../app-common/components/input/checkbox.intermediate";
import React from "react";
import {TableComponent} from "../../../../app-common/components/table/table";
import {NUMBER_BULK_DELETE, NUMBER_LIST, SENDER_NAME_BULK_DELETE} from "../../../../api/routing/routes/backend.app";
import {NumberModel} from "../../../../api/model/number";
import {Link} from "react-router-dom";
import moment from "moment";

export const Numbers = () => {
  const {t} = useTranslation();
  const columnHelper = createColumnHelper<NumberModel>();

  const columns = [
    columnHelper.accessor('uuid', {
      enableSorting: false,
      header: ({table}) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      cell: ({row}) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    }),
    columnHelper.accessor('name', {
      header: () => t('Name'),
    }),
    columnHelper.accessor('number', {
      header: () => t('Number'),
    }),
    columnHelper.accessor('groups', {
      header: () => t('Groups'),
      cell: info => info.getValue().map(grp => grp.name).join(', ')
    }),
    columnHelper.accessor('isActive', {
      header: () => t('Active?'),
      cell: info => info.getValue() ? t('Yes') : t('No')
    }),
    columnHelper.accessor('updatedAt.datetime', {
      header: () => t('Updated at'),
      cell: info => moment(info.getValue()).format('lll')
    }),
    columnHelper.accessor('uuid', {
      header: () => t('Actions'),
      cell: info => (
        <Link to={NUMBERS_EDIT.replace(':id', info.getValue())} className="btn btn-primary">
          {t('Update')}
        </Link>
      ),
    })
  ];

  return (
    <DashboardLayout
      title="Numbers"
      breadCrumbs={[
        {title: t('Dashboard'), link: DASHBOARD},
        {title: t('Numbers'), current: true}
      ]}
      buttons={[
        <Link to={NUMBERS_CREATE} className="float-end btn btn-primary">{t('Create')}</Link>
      ]}
    >
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                {t('List')}
              </h5>
              <TableComponent columns={columns} url={NUMBER_LIST} selectionButtons={[{
                title: <i className="bi bi-trash3"></i>,
                type: 'bulkDelete',
                className: 'btn btn-danger',
                url: NUMBER_BULK_DELETE
              }]}/>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
