import {FC, useEffect} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {getErrorClass, getErrors} from "../../../../lib/error/error";
import {ConstraintViolation} from "../../../../lib/validator/validation.result";
import {SenderName} from "../../../../api/model/user";

interface SenderNameFormProps {
  onSubmit: (values: any) => void;
  senderName?: SenderName;
  isSubmitting: boolean;
  errors: ConstraintViolation[];
}

export const SenderNameForm: FC<SenderNameFormProps> = ({
                                                          onSubmit, senderName, errors: formError, isSubmitting
                                                        }) => {
  const {handleSubmit, register, setError, reset, formState: {errors}} = useForm({
    reValidateMode: 'onChange'
  });
  const {t} = useTranslation();

  useEffect(() => {
    formError.forEach(item => {
      setError(item.propertyPath, {
        message: item.message,
        type: 'server'
      });

    });
  }, [formError, setError]);

  useEffect(() => {
    reset({
      ...senderName,
    });
  }, [senderName, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row tw-gap-4">
      <div className="col-12">
        <label htmlFor="name" className="form-label">{t('Name')}</label>
        <input
          type="text"
          className={classNames(
            "form-control", getErrorClass(errors.name)
          )}
          id="name"
          {...register('name')}
        />
        {getErrors(errors.name)}
      </div>
      <div className="col-12">
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="switch"
            {...register('isActive')}
          />
          <label className="form-check-label" htmlFor="switch">{t('Active?')}</label>
        </div>
      </div>
      <div className="col-12">
        <button className="btn btn-primary" disabled={isSubmitting}>{t('Save')}</button>
      </div>
    </form>
  );
};
