 import {useSelector} from "react-redux";
import {isUserLoggedIn} from "../../../duck/auth/auth.selector";
import React from "react";
import {Link, useLocation} from "react-router-dom";
import {
  DASHBOARD,
  GROUPS,
  NUMBERS,
  PENDING,
  SENDER_NAMES,
  SEND_MESSAGES,
  SENT, TEMPLATES, TEMPLATES_CREATE,
  TIMED_MESSAGES,
  USERS, PACKAGES, PACKAGES_CREATE, SENDER_NAMES_CREATE, PACKAGES_EDIT, NUMBERS_CREATE, GROUPS_CREATE
} from "../../routes/frontend.routes";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

export const Sidebar = () => {
  const isLoggedIn = useSelector(isUserLoggedIn);
  const {t} = useTranslation();

  const location = useLocation();

  if (!isLoggedIn) {
    return (<></>);
  }

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <Link className={classNames(
            "nav-link", location.pathname === DASHBOARD ? 'active' : 'collapsed'
          )} to={DASHBOARD}>
            <i className="bi bi-grid"></i>
            <span>{t('Dashboard')}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className={classNames(
            "nav-link", (
              location.pathname === SENDER_NAMES ||
              location.pathname === SENDER_NAMES_CREATE
            ) ? 'active' : 'collapsed'
          )} to={SENDER_NAMES}>
            <i className="bi bi-list-ol"></i>
            <span>{t('Sender Names')}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className={classNames(
            "nav-link", location.pathname === USERS ? 'active' : 'collapsed'
          )} to={USERS}>
            <i className="bi bi-people"></i>
            <span>{t('Users')}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className={classNames(
            "nav-link", (
              location.pathname === GROUPS ||
              location.pathname === GROUPS_CREATE
            ) ? 'active' : 'collapsed'
          )} to={GROUPS}>
            <i className="bi bi-people-fill"></i>
            <span>{t('Groups')}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className={classNames(
            "nav-link", (
              location.pathname === NUMBERS ||
              location.pathname === NUMBERS_CREATE
            ) ? 'active' : 'collapsed'
          )} to={NUMBERS}>
            <i className="bi bi-123"></i>
            <span>{t('Numbers')}</span>
          </Link>
        </li>

        <li className="nav-item">
          <Link className={classNames(
            "nav-link", location.pathname === SENT ? 'active' : 'collapsed'
          )} to={SENT}>
            <i className="bi bi-envelope-check"></i>
            <span>{t('Sent Archive')}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className={classNames(
            "nav-link", location.pathname === TIMED_MESSAGES ? 'active' : 'collapsed'
          )} to={TIMED_MESSAGES}>
            <i className="bi bi-clock-history"></i>
            <span>{t('Timed Messages Archive')}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className={classNames(
            "nav-link", location.pathname === PENDING ? 'active' : 'collapsed'
          )} to={PENDING}>
            <i className="bi bi-hourglass-split"></i>
            <span>{t('Pending Messages Archive')}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className={classNames(
            "nav-link", (
              location.pathname === TEMPLATES ||
                location.pathname === TEMPLATES_CREATE
            ) ? 'active' : 'collapsed'
          )} to={TEMPLATES}>
            <i className="bi bi-clipboard"></i>
            <span>{t('Templates')}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className={classNames(
            "nav-link", (
              location.pathname === PACKAGES ||
              location.pathname === PACKAGES_CREATE
            ) ? 'active' : 'collapsed'
          )} to={PACKAGES}>
            <i className="bi bi-basket"></i>
            <span>{t('Packages')}</span>
          </Link>
        </li>
      </ul>
    </aside>
  );
};
