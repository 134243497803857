import {DashboardLayout} from "../../layout/dashboard.layout";
import {useTranslation} from "react-i18next";
import {DASHBOARD} from "../../../routes/frontend.routes";
import {TableComponent} from "../../../../app-common/components/table/table";
import {SENT_LIST} from "../../../../api/routing/routes/backend.app";
import React from "react";
import {createColumnHelper} from "@tanstack/react-table";
import {Sent} from "../../../../api/model/sent";
import {IndeterminateCheckbox} from "../../../../app-common/components/input/checkbox.intermediate";
import moment from "moment";

export const TimedMessagesArchive = () => {
  const {t} = useTranslation();

  const columnHelper = createColumnHelper<Sent>();

  const columns = [
    columnHelper.accessor('id', {
      enableSorting: false,
      header: ({table}) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      cell: ({row}) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    }),
    columnHelper.accessor('message', {
      header: () => t('Message'),
    }),
    columnHelper.accessor('createdAt.datetime', {
      header: () => t('Created At'),
      cell: info => moment(info.getValue()).format('lll')
    }),
    columnHelper.accessor('sendAt', {
      header: () => t('Send At'),
      cell: info => moment(info.getValue()).format('lll')
    }),
    columnHelper.accessor('number', {
      header: () => t('Number'),
    })
  ];

  return (
    <DashboardLayout
      title="Timed Messages Archive"
      breadCrumbs={[
        {title: t('Dashboard'), link: DASHBOARD},
        {title: t('Timed Messages Archive'), current: true}
      ]}
    >
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">{t('List')}</h5>
              <TableComponent columns={columns} url={SENT_LIST} params={{
                isTimed: true
              }}  sort={[{id: 'createdAt_datetime', desc: true}]}/>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
