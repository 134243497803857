import {FC, useEffect} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Textarea} from "../../../../app-common/components/input/textarea";
import classNames from "classnames";
import {getErrorClass, getErrors} from "../../../../lib/error/error";
import {ConstraintViolation} from "../../../../lib/validator/validation.result";
import {Package} from "../../../../api/model/package";

interface PackageFormProps {
  onSubmit: (values: any) => void;
  package?: Package;
  isSubmitting: boolean;
  errors: ConstraintViolation[];
}

export const PackageForm: FC<PackageFormProps> = ({
  onSubmit, package: pkg, errors: formError, isSubmitting
}) => {
  const {handleSubmit, register, control, setError, reset, formState: {errors}, watch, getValues} = useForm({
    reValidateMode: 'onChange'
  });
  const {t} = useTranslation();

  useEffect(() => {
    formError.forEach(item => {
      setError(item.propertyPath, {
        message: item.message,
        type: 'server'
      });

    });
  }, [formError, setError]);

  useEffect(() => {
    reset({
      ...pkg
    });
  }, [pkg, reset]);

  useEffect(() => {
    reset({
      ...getValues(),
      pointsPerMessage: (watch('price') / watch('messages')).toFixed(2)
    });
  }, [watch('messages'), watch('price')]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row tw-gap-4">
      <div className="col-12">
        <label htmlFor="name" className="form-label">{t('Name')}</label>
        <input
          type="text"
          className={classNames(
            "form-control", getErrorClass(errors.name)
          )}
          id="name"
          {...register('name')}
        />
        {getErrors(errors.name)}
      </div>
      <div className="col-12">
        <Textarea
          label="Description"
          control={control}
          name="description"
          error={errors?.description?.message as unknown as string}
        />
      </div>
      <div className="col-12">
        <label htmlFor="price" className="form-label">{t('Price')}</label>
        <input
          type="text"
          className={classNames(
            "form-control", getErrorClass(errors.price)
          )}
          id="price"
          {...register('price')}
        />
        {getErrors(errors.price)}
      </div>
      <div className="col-12">
        <label htmlFor="messages" className="form-label">{t('Messages')}</label>
        <input
          type="text"
          className={classNames(
            "form-control", getErrorClass(errors.messages)
          )}
          id="messages"
          {...register('messages')}
        />
        {getErrors(errors.messages)}
      </div>
      <div className="col-12">
        <label htmlFor="pointsPerMessage" className="form-label">{t('Points per message')}</label>
        <input
          type="text"
          className={classNames(
            "form-control", getErrorClass(errors.pointsPerMessage)
          )}
          id="pointsPerMessage"
          readOnly
          {...register('pointsPerMessage')}
        />
        {getErrors(errors.pointsPerMessage)}
      </div>
      <div className="col-12">
        <button className="btn btn-primary" disabled={isSubmitting}>{t('Save')}</button>
      </div>
    </form>
  );
};
