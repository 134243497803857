import React, {useState} from 'react';
import {Button, Card, CardBody, CardTitle, Form, FormGroup, Input} from 'reactstrap';
import Layout from "../layout/layout";
import {jsonRequest} from "../../../api/request/request";
import {REGISTER} from "../../../api/routing/routes/backend.app";
import {Controller, useForm} from "react-hook-form";
import {ConstraintViolation} from "../../../lib/validator/validation.result";
import {getErrors, hasErrors} from "../../../lib/error/error";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {userAuthenticated} from "../../../duck/auth/auth.action";
import {Link} from "react-router-dom";
import {FORGOT_PASSWORD, LOGIN, SIGNUP} from "../../routes/frontend.routes";
import {useTranslation} from "react-i18next";
import {
  HttpException,
  UnauthorizedException,
  UnprocessableEntityException
} from "../../../lib/http/exception/http.exception";

const Signup = () => {

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const {handleSubmit, setError, formState: {errors}, control} = useForm();


  const navigate = useNavigate();
  const {t}  = useTranslation();

  const submitForm = async (values: any) => {
    setLoading(true);
    setErrorMessage(undefined);

    const requestOptions = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(values)
    };

    try {
      let response = await jsonRequest(REGISTER, requestOptions);
      await response.json();

      navigate(LOGIN);
    } catch (e: any) {
      if(e instanceof HttpException){
        setErrorMessage(e.message);
      }

      if(e instanceof UnauthorizedException){
        const res = await e.response.json();
        setErrorMessage(t(res.message));
      }

      if (e instanceof UnprocessableEntityException) {
        let errorResponse = await e.response.json();

        if (errorResponse.violations) {
          errorResponse.violations.forEach((error: ConstraintViolation) => {
            setError(error.propertyPath, {
              type: 'server',
              message: error.message
            });
          });
        }
      }
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Layout>
        <div className="container">
          <section
            className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">{t('Create Your Account')}</h5>
                      </div>
                      {errorMessage !== undefined && (
                        <div className="alert alert-danger">{errorMessage}</div>
                      )}
                      <Form onSubmit={handleSubmit(submitForm)} className="row">
                        <FormGroup>
                          <label htmlFor="displayName" className="form-label">{t('Name')}</label>
                          <Controller
                            render={(props) => (
                              <Input
                                {...props.field}
                                type="text"
                                id="displayName"
                                invalid={hasErrors(errors.displayName)}
                                autoFocus
                              />
                            )}
                            name="displayName"
                            control={control}
                          />
                          {getErrors(errors.displayName)}
                        </FormGroup>
                        <FormGroup>
                          <label htmlFor="email" className="form-label">{t('Email')}</label>
                          <Controller
                            render={(props) => (
                              <Input
                                {...props.field}
                                type="email"
                                id="email"
                                invalid={hasErrors(errors.email)}
                              />
                            )}
                            name="email"
                            control={control}
                          />
                          {getErrors(errors.email)}
                        </FormGroup>
                        <FormGroup>
                          <label htmlFor="username" className="form-label">{t('Username')}</label>
                          <Controller
                            render={(props) => (
                              <Input
                                {...props.field}
                                type="text"
                                id="username"
                                invalid={hasErrors(errors.username)}
                              />
                            )}
                            name="username"
                            control={control}
                          />

                          {getErrors(errors.username)}
                        </FormGroup>
                        <FormGroup>
                          <label htmlFor="password" className="form-label">{t('Password')}</label>
                          <Controller
                            render={(props) => (
                              <Input
                                {...props.field}
                                type="password"
                                id="password"
                                invalid={hasErrors(errors.password)}
                              />
                            )}
                            name="password"
                            control={control}
                          />
                          {getErrors(errors.password)}
                        </FormGroup>
                        <div className="col-12">
                          <Button color="primary" type="submit" disabled={isLoading} className="w-100">{t('Signup')}</Button>
                        </div>
                        <div className="col-12 mt-3 d-flex justify-content-between">
                          <Link to={LOGIN}>{t('Login')}</Link>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Signup;
