import {DashboardLayout} from "../../layout/dashboard.layout";
import {useTranslation} from "react-i18next";
import {DASHBOARD} from "../../../routes/frontend.routes";
import {createColumnHelper,} from '@tanstack/react-table'
import React from "react";
import {USER_LIST} from "../../../../api/routing/routes/backend.app";
import {User} from "../../../../api/model/user";
import {TableComponent} from "../../../../app-common/components/table/table";

export const Users = () => {
  const {t} = useTranslation();

  const columnHelper = createColumnHelper<User>();

  const columns = [
    columnHelper.accessor('username', {
      header: () => t('Username'),
    }),
    columnHelper.accessor('displayName', {
      header: () => t('Name'),
    }),
    columnHelper.accessor('balance.balance', {
      header: () => t('Balance'),
    }),
    columnHelper.accessor('roles', {
      header: () => t('Roles'),
      cell: info => info.getValue().join(', ')
    }),
  ];

  return (
    <DashboardLayout
      title="Users"
      breadCrumbs={[
        {title: t('Dashboard'), link: DASHBOARD},
        {title: t('Users'), current: true}
      ]}
    >
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">{t('List')}</h5>
              <TableComponent columns={columns} url={USER_LIST}/>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
