import {DashboardLayout} from "../../layout/dashboard.layout";
import {DASHBOARD} from "../../../routes/frontend.routes";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getAuthorizedUser} from "../../../../duck/auth/auth.selector";
import moment from "moment";
import {Package} from "../../../../api/model/package";
import {jsonRequest} from "../../../../api/request/request";
import {PACKAGE_LIST} from "../../../../api/routing/routes/backend.app";
import {withCurrency} from "../../../../lib/currency/currency";


export const Balance = () => {
  const {t} = useTranslation();

  const user = useSelector(getAuthorizedUser);
  const [packages, setPackages] = useState<Package[]>([]);
  const loadPackages = async () => {
    try{
      const res = await jsonRequest(PACKAGE_LIST);
      const json = await res.json();

      setPackages(json.list);
    }catch (e){
      throw e;
    }
  };

  useEffect(() => {
    loadPackages();
  }, []);

  return (
    <DashboardLayout
      title="Balance"
      breadCrumbs={[
        {title: t('Dashboard'), link: DASHBOARD},
        {title: t('Balance'), current: true}
      ]}
    >
      <div className="row">
        <div className="col-12">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                      type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">
                {t('Current Balance')}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                      type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">
                {t('History')}
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
                 tabIndex={0}>
              <div className="card mt-3">
                <div className="card-body">
                  <h5 className="card-title">
                    {t('List')}
                  </h5>
                  <h4>
                    {t('Available balance')}: <span className="tw-text-green-500 tw-font-bold">{user?.balance?.balance}</span>{' '}
                    {user?.balance?.validTill && (
                      <span>
                        {t('valid till')}{' '}
                        <span className="tw-text-red-500 tw-font-bold">
                          {moment(user?.balance?.validTill).format('lll')}
                        </span>
                      </span>
                    )}
                  </h4>

                  <h3>{t('Recharge balance')}</h3>
                  <div className="row">
                    {packages.map(pkg => (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                        <div className="card mb-4 border-primary">
                          <div className="card-header py-3 text-bg-primary border-primary">
                            <h4 className="my-0 fw-normal">{pkg.name}</h4>
                          </div>
                          <div className="card-body">
                            <h1 className="card-title pricing-card-title">
                              {withCurrency(pkg.price)}
                            </h1>
                            <div className="mt-3 mb-4">
                              <div><i className="bi bi-check2 tw-text-green-500 tw-text-[20px] tw-align-middle"></i> {pkg.description}</div>
                              <div><i className="bi bi-check2 tw-text-green-500 tw-text-[20px] tw-align-middle"></i> {pkg.messages} {t('messages')}</div>
                              <div><i className="bi bi-check2 tw-text-green-500 tw-text-[20px] tw-align-middle"></i> {t('Points per message')} {pkg.pointsPerMessage}</div>
                            </div>
                            <button type="button" className="w-100 btn btn-lg btn-outline-primary">Get Started</button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
                 tabIndex={0}>
              <div className="card mt-3">
                <div className="card-body">
                  <h5 className="card-title">
                    {t('List')}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
