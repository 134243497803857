import {DashboardLayout} from "../../layout/dashboard.layout";
import {useTranslation} from "react-i18next";
import {DASHBOARD, PENDING, SENT} from "../../../routes/frontend.routes";
import {Controller, useForm} from "react-hook-form";
import AsyncSelect from 'react-select/async';
import {jsonRequest, request} from "../../../../api/request/request";
import {
  GROUP_LIST,
  MEDIA_DOWNLOAD,
  MEDIA_UPLOAD,
  NUMBER_LIST,
  SENDER_NAME_LIST,
  SENT_CREATE, TEMPLATE_LIST
} from "../../../../api/routing/routes/backend.app";
import {QueryString} from "../../../../lib/location/query.string";
import {NumberModel} from "../../../../api/model/number";
import {Group} from "../../../../api/model/group";
import {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faTimes} from "@fortawesome/free-solid-svg-icons";
import {ReactSelect} from "../../../../app-common/components/input/custom.react.select";
import {HttpException, UnprocessableEntityException} from "../../../../lib/http/exception/http.exception";
import {useAlert} from "react-alert";
import {ConstraintViolation} from "../../../../lib/validator/validation.result";
import {getErrorClass, getErrors} from "../../../../lib/error/error";
import {useNavigate} from "react-router";
import classNames from "classnames";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {nanoid} from "nanoid";
import {Media} from "../../../../api/model/media";
import {SenderName} from "../../../../api/model/user";
import {Template} from "../../../../api/model/sent";


export interface ReactSelectOptionProp {
  label: string;
  value: string;
  [name: string]: string;
}

export const SendMessages = () => {
  const {t} = useTranslation();
  const [csvNumbers, setCsvNumbers] = useState<string[]>([]);
  const [language, setLanguage] = useState<string | undefined>('en');
  const alert = useAlert();
  const [sendMode, setSendMode] = useState('now');
  const [messageMode, setMessageMode] = useState('plain');

  const navigate = useNavigate();

  const {acceptedFiles, getRootProps, getInputProps, isDragActive} = useDropzone({
    multiple: false,
  });

  useEffect(() => {
    acceptedFiles.forEach((file) => {
      const allowedTypes = [
        'text/csv', 'text/plain'
      ];
      if (allowedTypes.indexOf(file.type) === -1) {
        alert.error(t('Please select a valid CSV or Text file'));
        return false;
      }

      const reader = new FileReader()

      reader.onerror = () => alert.error(t('Unable to read file'));
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        const numbers: string[] = [...csvNumbers];
        if (typeof binaryStr === 'string') {
          if(file.type === 'text/csv') {
            binaryStr?.split("\n").forEach((item, k) => {
              if (k !== 0) {
                const a = item.split(',');
                if (a.length >= 3) {
                  //add only valid and unique numbers
                  if (a[2].trim() !== '' && numbers.indexOf(a[2]) === -1) {
                    numbers.push(a[2]);
                  }
                }
              }
            });
          }

          if(file.type === 'text/plain'){
            binaryStr.split(',').forEach(item => {
              if(numbers.indexOf(item) === -1) {
                numbers.push(item);
              }
            });
          }



          setCsvNumbers(numbers);
        }
      }
      reader.readAsBinaryString(file)
    })
  }, [acceptedFiles]);

  const [isSubmitting, setSubmitting] = useState(false);

  const {control, handleSubmit, setError, formState: {errors}, register, reset, watch, getValues} = useForm({
    reValidateMode: 'onChange'
  });
  const submitForm = async (values: any) => {
    setSubmitting(true);

    if (values.groups) {
      const groups = values.groups;
      values.groups = groups.map((item: ReactSelectOptionProp) => {
        return item.value;
      });
    }

    if (values.numbers) {
      const numbers = values.numbers;
      values.numbers = numbers.map((item: ReactSelectOptionProp) => {
        return item.value;
      });
    }

    values.csvNumbers = csvNumbers;

    if (values.senderName) {
      values.senderName = values.senderName.value;
    }

    if (values.numbersList) {
      values.numbersList = values.numbersList.split(',');
    } else {
      values.numbersList = null;
    }

    if(values.template){
      values.template = values.template.value;
    }

    try {
      await jsonRequest(SENT_CREATE, {
        method: 'post',
        body: JSON.stringify(values)
      });

      navigate(PENDING);

    } catch (e) {
      if (e instanceof UnprocessableEntityException) {
        const err = await e.response.json();
        if (err.violations) {
          err.violations.forEach((item: ConstraintViolation) => {
            setError(item.propertyPath, {
              message: item.message,
              type: 'server'
            });
          });
        }

        if (err.errorMessage) {
          alert.error(err.errorMessage, {
            timeout: 6000
          });
        }
      }

      if (e instanceof HttpException) {
        alert.error(e.message);
      }

      throw e;
    } finally {
      setSubmitting(false);
    }
  };

  const [senderNames, setSenderNames] = useState<ReactSelectOptionProp[]>([]);
  const loadSenderNames = async () => {
    try {
      const query = QueryString.stringify({
        isApproved: true,
        isActive: true
      });

      const res = await jsonRequest(`${SENDER_NAME_LIST}?${query}`);
      const json = await res.json();

      setSenderNames(json.list.map((item: SenderName) => {
        return {
          label: item.name,
          value: item.uuid
        };
      }));

    } catch (e) {
      throw e;
    }
  };

  const [templates, setTemplates] = useState<ReactSelectOptionProp[]>([]);
  const loadTemplates = async () => {
    try {
      const query = QueryString.stringify({
        isActive: true
      });

      const res = await jsonRequest(`${TEMPLATE_LIST}?${query}`);
      const json = await res.json();

      setTemplates(json.list.map((item: Template) => {
        return {
          label: item.name,
          value: item.uuid,
          message: item.message
        };
      }));

    } catch (e) {
      throw e;
    }
  };

  const loadNumbers = async (
    inputValue: string,
    callback: (options: ReactSelectOptionProp[]) => any
  ) => {
    try {
      const query = QueryString.stringify({
        q: inputValue,
        limit: 50
      });

      const res = await jsonRequest(`${NUMBER_LIST}?${query}`);
      const json = await res.json();

      return callback(json.list.map((item: NumberModel) => {
        return {
          label: item.number,
          value: item.number
        };
      }));
    } catch (e) {
      throw e;
    }
  };

  const loadGroups = async (
    inputValue: string,
    callback: (options: ReactSelectOptionProp[]) => any
  ) => {
    try {
      const query = QueryString.stringify({
        q: inputValue,
        limit: 50
      });

      const res = await jsonRequest(`${GROUP_LIST}?${query}`);
      const json = await res.json();

      return callback(json.list.map((item: Group) => {
        return {
          label: `${item.name} (${item.numbers})`,
          value: item.uuid
        };
      }));
    } catch (e) {
      throw e;
    }
  };

  const [isUploading, setUploading] = useState(false);
  const uploadFile = async (event: any) => {
    setUploading(true);
    const files: FileList = event.target.files;

    const formData = new FormData();
    if (files) {
      formData.append('file', files[0]);
    }
    formData.append('id', nanoid(11));

    try {
      const res = await request(MEDIA_UPLOAD, {
        method: 'POST',
        body: formData
      });

      const json: { media: Media } = await res.json();

      const prevValue = watch('message');

      reset({
        ...getValues(),
        'message': (prevValue ? prevValue + ' ' : '') + MEDIA_DOWNLOAD.replace(':name', json.media.name)
      });
    } catch (e) {
      throw e;
    } finally {
      setUploading(false);
    }
  };

  const messages = Math.ceil(watch('message')?.length / (language === 'en' ? 160 : 120));

  useEffect(() => {
    loadSenderNames();
    loadTemplates();
  }, []);

  const removeNumber = (item: string) => {
    setCsvNumbers(prev => prev.filter(pItem => pItem !== item));
  }

  const [template, setTemplate] = useState<ReactSelectOptionProp>();

  useEffect(() => {
    if(watch('messageMode') === 'template'){
      reset({
        ...getValues(),
        message: template?.message
      });
    }
  }, [reset, template]);

  return (
    <DashboardLayout
      title="Send Messages"
      breadCrumbs={[
        {title: t('Dashboard'), link: DASHBOARD},
        {title: t('Send Messages'), current: true}
      ]}
    >
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="row gap-3">
          <div className="col-12">
            <div className="card">
              <div className="card-body row g-3 pt-3">
                <div className="col-12">
                  <label htmlFor="senderName" className="form-label">{t('Sender name')}</label>
                  <Controller
                    render={(props) => (
                      <ReactSelect
                        options={senderNames}
                        onChange={props.field.onChange}
                        value={props.field.value}
                        id="senderName"
                        className={
                          classNames(
                            'react-select', getErrorClass(errors.senderName)
                          )
                        }
                        classNamePrefix="react-select"
                        placeholder={t('Select')}
                      />
                    )}
                    name="senderName"
                    control={control}
                  />
                  {getErrors(errors.senderName)}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body g-3 row pt-3">
                {getErrors(errors.numbers)}
                <div className="col-6">
                  <label htmlFor="numbers" className="form-label">{t('Numbers')}</label>
                  <Controller
                    render={(props) => (
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadNumbers}
                        defaultOptions
                        isMulti
                        onChange={props.field.onChange}
                        value={props.field.value}
                        id="numbers"
                        className={
                          classNames(
                            'react-select'
                          )
                        }
                        classNamePrefix="react-select"
                        placeholder={t('Select')}
                      />
                    )}
                    name="numbers"
                    control={control}
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="groups" className="form-label">{t('Groups')}</label>
                  <Controller
                    render={(props) => (
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadGroups}
                        defaultOptions
                        isMulti
                        onChange={props.field.onChange}
                        value={props.field.value}
                        id="groups"
                        className={
                          classNames(
                            'react-select', getErrorClass(errors.groups)
                          )
                        }
                        classNamePrefix="react-select"
                        placeholder={t('Select')}
                      />
                    )}
                    name="groups"
                    control={control}
                  />
                  {getErrors(errors.groups)}
                </div>
                <div className="col-6">
                  <label htmlFor="file" className="form-label">{t('Load numbers from CSV/TXT file')}</label>
                  <div {...getRootProps({
                    className: 'dropzone'
                  })}>
                    <input {...getInputProps()} />
                    {
                      isDragActive ?
                        <p>{t('Drop the files here')} ...</p> :
                        <p>{t("Drag 'n' drop some files here, or click to select files")}</p>
                    }
                  </div>
                  <div className="mt-3 d-flex tw-gap-3 tw-flex-wrap">
                    {csvNumbers.map(item => (
                      <div
                        className="tw-inline-flex tw-rounded tw-overflow-hidden tw-justify-between tw-items-center tw-text-blue-500"
                        key={item}
                      >
                        <span className="tw-px-2 tw-py-1 tw-bg-blue-50">
                          {item}
                        </span>
                        <span
                          className="tw-px-2 tw-py-1 tw-bg-red-200 tw-cursor-pointer"
                          title={t('Remove')}
                          onClick={() => removeNumber(item)}
                        >
                          <FontAwesomeIcon icon={faTimes} className="tw-text-red-500"/>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-6">
                  <label htmlFor="numbers-list" className="form-label">
                    {t('Add numbers direct and separate between them with comma ( , )')}
                  </label>
                  <textarea
                    {...register('numbersList')}
                    className={classNames(
                      'form-control', getErrorClass(errors.numbers_list)
                    )}
                    id="numbers-list"
                  />
                  {getErrors(errors.numbers_list)}
                  <div
                    className="small text-muted">{t('Numbers must be entered in international format 966500000000 and international messages without 00 or +')}</div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body g-3 row pt-3">
                <div className="col-12">
                  <label htmlFor="language" className="form-label">{t('Language')}</label>
                  <ReactSelect
                    options={[{
                      label: 'English',
                      value: 'en'
                    }, {
                      label: 'Arabic',
                      value: 'ar'
                    }]}
                    onChange={(value) => {
                      setLanguage(value?.value);
                    }}
                    defaultValue={{
                      label: 'English',
                      value: 'en'
                    }}
                    placeholder={t('Select')}
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="attach-files" className="form-label">
                    {t('Add attachment')} {isUploading ? (
                    <FontAwesomeIcon icon={faSpinner} spin/>
                  ) : ''}
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="attach-files"
                    onChange={uploadFile}
                    disabled={isUploading}
                  />
                </div>
                <div className="col-12">
                  <Controller
                    render={(props) => (
                      <>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input" type="radio" id="message-mode-plain"
                            value="plain" onClick={() => setMessageMode('plain')}
                            onChange={props.field.onChange}
                            checked={props.field.value === 'plain'}
                          />
                          <label className="form-check-label" htmlFor="message-mode-plain">{t('Create new message')}</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input" type="radio" id="message-mode-template"
                            value="template" onClick={() => setMessageMode('template')}
                            onChange={props.field.onChange}
                            checked={props.field.value === 'template'}
                          />
                          <label className="form-check-label" htmlFor="message-mode-template">{t('Choose from a template')}</label>
                        </div>
                      </>
                    )}
                    name="messageMode"
                    control={control}
                    defaultValue="plain"
                  />
                </div>

                {messageMode === 'template' && (
                  <div className="col-12">
                    <label htmlFor="template" className="form-label">{t('Choose a template')}</label>
                    <Controller
                      render={(props) => (
                        <ReactSelect
                          options={templates}
                          onChange={(value) => {
                            props.field.onChange(value);
                            setTemplate(value);
                          }}
                          value={props.field.value}
                          id="template"
                          className={
                            classNames(
                              'react-select', getErrorClass(errors.template)
                            )
                          }
                          classNamePrefix="react-select"
                          placeholder={t('Select')}
                        />
                      )}
                      name="template"
                      control={control}
                    />
                    {getErrors(errors.template)}
                  </div>
                )}
                <div className="col-12">
                  <label htmlFor="message" className="form-label">{t('Message')}</label>
                  <textarea
                    className={
                      classNames(
                        "form-control", getErrorClass(errors.message)
                      )
                    }
                    id="message"
                    {...register('message')}
                  />
                  {getErrors(errors.message)}
                  <span
                    className="text-muted small">{watch('message')?.length} {t('characters')} | {messages} {messages === 1 ? t('message') : t('messages')}</span>
                </div>


                <div className="col-12">
                  <Controller
                    render={(props) => (
                      <>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input" type="radio" id="inlineRadio1"
                            value="now" onClick={() => setSendMode('now')}
                            onChange={props.field.onChange}
                            checked={props.field.value === 'now'}
                          />
                          <label className="form-check-label" htmlFor="inlineRadio1">{t('Send now')}</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input" type="radio" id="inlineRadio2"
                            value="later" onClick={() => setSendMode('later')}
                            onChange={props.field.onChange}
                            checked={props.field.value === 'later'}
                          />
                          <label className="form-check-label" htmlFor="inlineRadio2">{t('Send later')}</label>
                        </div>
                      </>
                    )}
                    name="sendMode"
                    control={control}
                    defaultValue="now"
                  />
                </div>

                {sendMode === 'later' && (
                  <div className="col-12">
                    <label htmlFor="send-later">{t('Send later at')}</label>
                    <Controller
                      render={(props) => (
                        <DatePicker
                          onChange={props.field.onChange}
                          selected={props.field.value}
                          className={
                            classNames(
                              "form-control", getErrorClass(errors.sendAt)
                            )
                          }
                          dateFormat="yyyy-MM-dd hh:mm:ss a"
                          showTimeSelect
                        />
                      )}
                      name="sendAt"
                      control={control}
                    />
                    {getErrors(errors.sendAt)}
                  </div>
                )}
              </div>
            </div>

            <div className="col-12">
              <button className="btn btn-primary" disabled={isSubmitting}>
                {t('Send Message')}
              </button>
            </div>
          </div>
        </div>
      </form>
    </DashboardLayout>
  );
};
