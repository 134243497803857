/**
 * open routes
 */
const staticRoute = (route: string) => route;

export const LOGIN = staticRoute('/');
export const SIGNUP = staticRoute('/sign-up');
export const FORGOT_PASSWORD = staticRoute('/forgot-password');

/**
 * protected routes
 */
export const DASHBOARD = staticRoute('/dashboard');

export const USERS = staticRoute('/users');
export const USERS_CREATE = staticRoute('/users/create');
export const USERS_EDIT = staticRoute('/users/edit/:id');

export const GROUPS = staticRoute('/groups');
export const GROUPS_CREATE = staticRoute('/groups/create');
export const GROUPS_EDIT = staticRoute('/groups/edit/:id');

export const NUMBERS = staticRoute('/numbers');
export const NUMBERS_CREATE = staticRoute('/numbers/create');
export const NUMBERS_EDIT = staticRoute('/numbers/edit/:id');

export const PENDING = staticRoute('/pending');
export const SEND_MESSAGES = staticRoute('/send-messages');
export const SENT = staticRoute('/sent-archive');
export const TIMED_MESSAGES = staticRoute('/timed-messages-archive');

export const TEMPLATES = staticRoute('/templates');
export const TEMPLATES_CREATE = staticRoute('/templates/create');
export const TEMPLATES_EDIT = staticRoute('/templates/edit/:id');

export const SENDER_NAMES = staticRoute('/sender-names');
export const SENDER_NAMES_CREATE = staticRoute('/sender-names/create');
export const SENDER_NAMES_EDIT = staticRoute('/sender-names/edit/:id');

export const PROFILE = staticRoute('/profile');

export const BALANCE = staticRoute('/balance');
