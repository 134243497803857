import {DashboardLayout} from "../../layout/dashboard.layout";
import {useTranslation} from "react-i18next";
import {DASHBOARD, NUMBERS} from "../../../routes/frontend.routes";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {jsonRequest} from "../../../../api/request/request";
import {NUMBER_CREATE} from "../../../../api/routing/routes/admin.backend.app";
import {HttpException, UnprocessableEntityException} from "../../../../lib/http/exception/http.exception";
import {useAlert} from "react-alert";
import {useNavigate} from "react-router";
import {NumberForm} from "./form";
import {ReactSelectOptionProp} from "../../../../app-frontend/containers/dashboard/send.messages";

export const CreateNumber = () => {
  const {t} = useTranslation();
  const alert = useAlert();

  const [isSubmitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();

  const onSubmit = async (values: any) => {
    setSubmitting(true);
    setErrors([]);

    if(values.groups){
      const groups = values.groups;
      values.groups = groups.map((item: ReactSelectOptionProp) => {
        return item.value;
      });
    }

    try {
      await jsonRequest(NUMBER_CREATE, {
        method: 'POST',
        body: JSON.stringify(values)
      });

      navigate(NUMBERS);

    } catch (e) {
      if (e instanceof UnprocessableEntityException) {
        const err = await e.response.json();
        setErrors(err.violations);
      }

      if (e instanceof HttpException) {
        alert.error(e.message);
      }

      throw e;
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <DashboardLayout
      title="Numbers"
      breadCrumbs={[
        {title: t('Dashboard'), link: DASHBOARD},
        {title: t('Numbers'), link: NUMBERS},
        {title: t('Create'), current: true},
      ]}
      buttons={[
        <Link to={NUMBERS} className="float-end btn btn-primary">{t('Back to list')}</Link>
      ]}
    >
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">
                {t('Create')}
              </h5>
              <NumberForm
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                errors={errors}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
