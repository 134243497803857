import {DashboardLayout} from "../../layout/dashboard.layout";
import {useTranslation} from "react-i18next";
import {DASHBOARD, TEMPLATES_EDIT} from "../../../routes/frontend.routes";
import {createColumnHelper,} from '@tanstack/react-table'
import React from "react";
import {USER_LIST} from "../../../../api/routing/routes/admin.backend.app";
import {User} from "../../../../api/model/user";
import {TableComponent} from "../../../../app-common/components/table/table";
import {Link} from "react-router-dom";

export const Users = () => {
  const {t} = useTranslation();

  const columnHelper = createColumnHelper<User>();

  const columns = [
    columnHelper.accessor('username', {
      header: () => t('Username'),
    }),
    columnHelper.accessor('displayName', {
      header: () => t('Name'),
    }),
    columnHelper.accessor('balance.balance', {
      header: () => t('Balance'),
    }),
    columnHelper.accessor('isActive', {
      header: () => t('Active?'),
    }),
    columnHelper.accessor('isBlocked', {
      header: () => t('Blocked?'),
    }),
    columnHelper.accessor('roles', {
      header: () => t('Roles'),
      cell: info => info.getValue().join(', ')
    }),
    columnHelper.accessor('uuid', {
      header: () => t('Actions'),
      cell: info => (
        <Link to={TEMPLATES_EDIT.replace(':id', info.getValue())} className="btn btn-primary">
          {t('Update')}
        </Link>
      ),
    })
  ];

  return (
    <DashboardLayout
      title="Users"
      breadCrumbs={[
        {title: t('Dashboard'), link: DASHBOARD},
        {title: t('Users'), current: true}
      ]}
    >
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">{t('List')}</h5>
              <TableComponent columns={columns} url={USER_LIST}/>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
