import {FC, useEffect} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Template} from "../../../../api/model/sent";
import {Textarea} from "../../../../app-common/components/input/textarea";
import classNames from "classnames";
import {getErrorClass, getErrors} from "../../../../lib/error/error";
import {ConstraintViolation} from "../../../../lib/validator/validation.result";

interface TemplateFormProps{
  onSubmit: (values: any) => void;
  template?: Template;
  isSubmitting: boolean;
  errors: ConstraintViolation[];
}

export const TemplateForm: FC<TemplateFormProps> = ({
  onSubmit, template, errors: formError, isSubmitting
}) => {
  const {handleSubmit, register, control, setError, reset, formState: {errors}} = useForm({
    reValidateMode: 'onChange'
  });
  const {t} = useTranslation();

  useEffect(() => {
    formError.forEach(item => {
      setError(item.propertyPath, {
        message: item.message,
        type: 'server'
      });

    });
  }, [formError, setError]);

  useEffect(() => {
    reset({
      ...template
    });
  }, [template, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row tw-gap-4">
      <div className="col-12">
        <label htmlFor="name" className="form-label">{t('Name')}</label>
        <input
          type="text"
          className={classNames(
            "form-control", getErrorClass(errors.name)
          )}
          id="name"
          {...register('name')}
        />
        {getErrors(errors.name)}
      </div>
      <div className="col-12">
        <Textarea
          label="Message"
          control={control}
          name="message"
          error={errors?.message?.message as unknown as string}
        />
      </div>
      <div className="col-12">
        <button className="btn btn-primary" disabled={isSubmitting}>{t('Save')}</button>
      </div>
    </form>
  );
};
