import Login from './containers/login/login';
import Signup from './containers/signup/signup';
import {BrowserRouter as Router, Route, useLocation} from "react-router-dom";
import {
  DASHBOARD, FORGOT_PASSWORD,
  GROUPS, GROUPS_CREATE, GROUPS_EDIT,
  LOGIN,
  NUMBERS, NUMBERS_CREATE, NUMBERS_EDIT,
  PENDING, PROFILE,
  SEND_MESSAGES, SENDER_NAMES,
  SENT,
  SIGNUP, TEMPLATES, TEMPLATES_CREATE, TEMPLATES_EDIT, TIMED_MESSAGES,
  USERS, USERS_CREATE, USERS_EDIT, SENDER_NAMES_EDIT, SENDER_NAMES_CREATE, BALANCE,
} from "./routes/frontend.routes";
import {connect, useSelector} from "react-redux";
import {RootState} from "../duck/_root/root.state";
import {isUserLoggedIn} from "../duck/auth/auth.selector";
import {getBootstrapError, hasBootstrapped} from "../duck/app/app.selector";
import {bootstrap} from "../duck/app/app.action";
import {userLoggedOut} from "../duck/auth/auth.action";
import {bindActionCreators, Dispatch} from 'redux';
import {FunctionComponent, useEffect} from "react";
import {Dashboard} from "./containers/dashboard/dashboard";
import {useLogout} from "../duck/auth/hooks/useLogout";
import {Navigate, Routes} from 'react-router';
import {Error404} from "../app-common/components/error/404";
import {Users} from "./containers/dashboard/users";
import {Groups} from "./containers/dashboard/groups";
import {Numbers} from "./containers/dashboard/numbers";
import {PendingArchive} from "./containers/dashboard/pending";
import {SendMessages} from "./containers/dashboard/send.messages";
import {SentArchive} from "./containers/dashboard/sent";
import {TimedMessagesArchive} from "./containers/dashboard/timed.messages";
import {ForgotPassword} from "./containers/forgot/forgot";
import {Profile} from "./containers/dashboard/profile/profile";
import {Templates} from "./containers/dashboard/templates";
import {CreateTemplate} from "./containers/dashboard/templates/create";
import {UpdateTemplate} from "./containers/dashboard/templates/update";
import {CreateGroup} from "./containers/dashboard/groups/create";
import {UpdateGroup} from "./containers/dashboard/groups/update";
import {CreateNumber} from "./containers/dashboard/numbers/create";
import {UpdateNumber} from "./containers/dashboard/numbers/update";
import {SenderNames} from "./containers/dashboard/sender.names";
import {CreateSenderName} from "./containers/dashboard/sender.names/create";
import {UpdateSenderName} from "./containers/dashboard/sender.names/update";
import {Balance} from "./containers/dashboard/balance";

export interface AppProps {
  bootstrap: () => void;
  userLoggedOut: () => void;
  isLoggedIn?: boolean;
  hasBootstrapped?: boolean;
  bootstrapError?: Error;
}


const AppComponent: FunctionComponent<AppProps> = (props) => {

  const [logoutState, logoutAction] = useLogout();

  useEffect(() => {
    props.bootstrap();

    function handleException(e: any) {
      if(e.reason.code === 401) {
        logoutAction();
      }
    }

    window.addEventListener('unhandledrejection', handleException);

    return () => window.removeEventListener('unhandledrejection', handleException);
  }, []);

  const {isLoggedIn, hasBootstrapped, bootstrapError} = props;

  if (!!bootstrapError) {
    return <div>An error occurred while initializing application</div>;
  }

  if (!hasBootstrapped) {
    return null;
  }


  return (
    <Router>
      <Routes>
        <Route path={LOGIN} element={
          <>
            {isLoggedIn ? <Navigate to={DASHBOARD}/> : <Login/>}
          </>
        }/>

        <Route path={FORGOT_PASSWORD} element={
          <>
            {isLoggedIn ? <Navigate to={DASHBOARD}/> : <ForgotPassword/>}
          </>
        }/>

        <Route path={SIGNUP} element={
          <>
            {isLoggedIn ? <Navigate to={DASHBOARD}/> : <Signup/>}
          </>
        }/>

        {/*Protected routes*/}
        <Route path={DASHBOARD} element={<RequireAuth><Dashboard/></RequireAuth>}/>
        <Route path={PROFILE} element={<RequireAuth><Profile/></RequireAuth>}/>

        <Route path={USERS} element={<RequireAuth><Users/></RequireAuth>}/>
        <Route path={USERS_CREATE} element={<RequireAuth><Users/></RequireAuth>}/>
        <Route path={USERS_EDIT} element={<RequireAuth><Users/></RequireAuth>}/>

        <Route path={GROUPS} element={<RequireAuth><Groups/></RequireAuth>}/>
        <Route path={GROUPS_CREATE} element={<RequireAuth><CreateGroup/></RequireAuth>}/>
        <Route path={GROUPS_EDIT} element={<RequireAuth><UpdateGroup/></RequireAuth>}/>

        <Route path={NUMBERS} element={<RequireAuth><Numbers/></RequireAuth>}/>
        <Route path={NUMBERS_CREATE} element={<RequireAuth><CreateNumber/></RequireAuth>}/>
        <Route path={NUMBERS_EDIT} element={<RequireAuth><UpdateNumber/></RequireAuth>}/>

        <Route path={SEND_MESSAGES} element={<RequireAuth><SendMessages/></RequireAuth>}/>

        <Route path={PENDING} element={<RequireAuth><PendingArchive/></RequireAuth>}/>
        <Route path={SENT} element={<RequireAuth><SentArchive/></RequireAuth>}/>
        <Route path={TIMED_MESSAGES} element={<RequireAuth><TimedMessagesArchive/></RequireAuth>}/>

        <Route path={TEMPLATES} element={<RequireAuth><Templates/></RequireAuth>}/>
        <Route path={TEMPLATES_CREATE} element={<RequireAuth><CreateTemplate/></RequireAuth>}/>
        <Route path={TEMPLATES_EDIT} element={<RequireAuth><UpdateTemplate/></RequireAuth>}/>

        <Route path={SENDER_NAMES} element={<RequireAuth><SenderNames/></RequireAuth>}/>
        <Route path={SENDER_NAMES_CREATE} element={<RequireAuth><CreateSenderName/></RequireAuth>}/>
        <Route path={SENDER_NAMES_EDIT} element={<RequireAuth><UpdateSenderName/></RequireAuth>}/>

        <Route path={BALANCE} element={<RequireAuth><Balance/></RequireAuth>}/>

        {/*if nothing matches show 404*/}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
  );
};

export const App = connect(
  (state: RootState) => ({
    isLoggedIn: isUserLoggedIn(state),
    hasBootstrapped: hasBootstrapped(state),
    bootstrapError: getBootstrapError(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        bootstrap: bootstrap,
        userLoggedOut,
      },
      dispatch
    )
)(AppComponent);

export const RequireAuth = ({children }: { children: JSX.Element }) => {
  let location = useLocation();
  const userLoggedIn = useSelector(isUserLoggedIn);

  if (!userLoggedIn) {
    return <Navigate
      to={LOGIN}
      state={{ from: location }}
      replace
    />;
  }

  return children;
}
