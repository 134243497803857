import {FC, useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Textarea} from "../../../../app-common/components/input/textarea";
import classNames from "classnames";
import {getErrorClass, getErrors} from "../../../../lib/error/error";
import {ConstraintViolation} from "../../../../lib/validator/validation.result";
import {NumberModel} from "../../../../api/model/number";
import AsyncSelect from "react-select/async";
import {QueryString} from "../../../../lib/location/query.string";
import {jsonRequest} from "../../../../api/request/request";
import {GROUP_LIST} from "../../../../api/routing/routes/backend.app";
import {Group} from "../../../../api/model/group";
import {ReactSelectOptionProp} from "../send.messages";

interface NumberFormProps {
  onSubmit: (values: any) => void;
  number?: NumberModel;
  isSubmitting: boolean;
  errors: ConstraintViolation[];
}

export const NumberForm: FC<NumberFormProps> = ({
                                                  onSubmit, number, errors: formError, isSubmitting
                                                }) => {
  const {handleSubmit, register, control, setError, reset, formState: {errors}} = useForm({
    reValidateMode: 'onChange'
  });
  const {t} = useTranslation();

  useEffect(() => {
    formError.forEach(item => {
      setError(item.propertyPath, {
        message: item.message,
        type: 'server'
      });

    });
  }, [formError, setError]);

  useEffect(() => {
    reset({
      ...number,
      groups: number?.groups?.map(group => {
        return {label: group.name, value: group.uuid}
      })
    });
  }, [number, reset]);

  const loadGroups = async (
    inputValue: string,
    callback: (options: ReactSelectOptionProp[]) => any
  ) => {
    try {
      const query = QueryString.stringify({
        q: inputValue,
        limit: 50
      });

      const res = await jsonRequest(`${GROUP_LIST}?${query}`);
      const json = await res.json();

      return callback(json.list.map((item: Group) => {
        return {
          label: item.name,
          value: item.uuid
        };
      }));
    } catch (e) {
      throw e;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row tw-gap-4">
      <div className="col-12">
        <label htmlFor="name" className="form-label">{t('Name')}</label>
        <input
          type="text"
          className={classNames(
            "form-control", getErrorClass(errors.name)
          )}
          id="name"
          {...register('name')}
        />
        {getErrors(errors.name)}
      </div>
      <div className="col-12">
        <label htmlFor="number" className="form-label">{t('Number')}</label>
        <input
          type="text"
          className={classNames(
            "form-control", getErrorClass(errors.number)
          )}
          id="number"
          {...register('number')}
        />
        {getErrors(errors.number)}
      </div>
      <div className="col-12">
        <label htmlFor="groups" className="form-label">{t('Groups')}</label>
        <Controller
          render={(props) => (
            <AsyncSelect
              cacheOptions
              loadOptions={loadGroups}
              defaultOptions
              isMulti
              onChange={props.field.onChange}
              value={props.field.value}
              id="groups"
              className={getErrorClass(errors.groups)}
              placeholder={t('Select')}
            />
          )}
          name="groups"
          control={control}
        />
        {getErrors(errors.groups)}
      </div>
      <div className="col-12">
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="switch"
            {...register('isActive')}
          />
          <label className="form-check-label" htmlFor="switch">{t('Active?')}</label>
        </div>
      </div>
      <div className="col-12">
        <button className="btn btn-primary" disabled={isSubmitting}>{t('Save')}</button>
      </div>
    </form>
  );
};
